.video-section {
  padding: 40px 0px;
  border-radius: 28px;
}

.video-section video {
  border-radius: 25px;
}

.addnickname-section {
  background: #fff;
  margin: 40px 0px;
  border-radius: 28px;
  padding: 20px;
}

.addnickname-section h1 {
  font-size: 25px;
  padding: 10px 0px;
  font-weight: normal;
}

.addnickname-section input {
  border-radius: 20px;
  background: #f7f7f8;
}

.media_selection_title {
  padding: 13px 0px;
  font-size: 32px;
  text-align: center;
}

.media_selection_background {
  /*width: 100%;*/
  background-color: #cccccc !important;
  ;
  background-position: center !important;
  ;
  background-repeat: repeat !important;
  ;
  height: 100%;
}

.main-container {
  margin: 0px auto;
}

.video-container {
  border: 1px solid #4e4e4e;
  border-radius: 20px;
  width: 300px;
  height: 250px;
  margin: 165px auto;
  background: #4e4e4e;
  margin-right: 10px;
  opacity: 0.8;
  cursor: pointer;
}

#fg {
  position: absolute;
  top: 0px;
  left: 0px;
}

#bg {
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(20px);
}

.photo-container {
  border: 1px solid #4e4e4e;
  border-radius: 20px;
  width: 300px;
  margin: 165px auto;
  background: #4e4e4e;
  margin-left: 10px;
  opacity: 0.8;
  cursor: pointer;
  height: 250px;
}

.add-photo-container {
  border: 1px solid #4e4e4e;
  width: 330px;
  margin: 0px auto;
  margin-bottom: 0px;
  margin-top: 40px;
  background: #4e4e4e;
  opacity: 0.8;
  height: 325px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  position: relative;
}

.add-video-container {
  border: 1px solid #4e4e4e;
  width: 330px;
  margin: 0px auto;
  margin-bottom: 0px;
  margin-top: 40px;
  background: #4e4e4e;
  opacity: 0.8;
  height: 400px;
  border-radius: 25px;
  position: relative;
}

.add-video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 25px;
  object-fit: cover;
}

.add-receiver-video-container {
  width: 330px;
  margin: 0px auto;
  margin-bottom: 0px;
  margin-top: 0px;
  background: #4e4e4e;
  height: 400px;
  border-radius: 25px;
  position: relative;
}

.add-receiver-video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 25px;
  object-fit: contain;
}

.video-change-button {
  width: 175px;
  background: #fff !important;
  margin: 20px 10px;
  text-align: center !important;
  border-radius: 25px !important;
  color: #000 !important;
}

.video-continue-button {
  width: 175px;
  margin: 20px 10px;
  text-align: center !important;
  border-radius: 25px !important;
}

.add-text-container {
  border: 1px solid #fff;
  width: 330px;
  margin: 0px auto;
  margin-top: 0px;
  margin-bottom: 0px;
  background: #fff;
  opacity: 0.94;
  height: 130px;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  cursor: pointer;
}

.icon-div {
  background-color: #f2efe8;
  border: 1px solid #f2efe8;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin: auto;
  margin-top: 70px;
}

.title-div {
  margin-top: 5px;
}

.photo-icon-div {
  background-color: #f2efe8;
  border: 1px solid #f2efe8;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  /*margin: 145px 167px auto;*/
  margin: 0px auto;
  position: relative;
  cursor: pointer;
}

.inner-photo-section {
  margin-top: 75px;
}

.addtext-icon-div {
  width: 65px;
  height: 65px;
  margin: 0px auto;
  margin-top: 10px;
}

.addtext-icon-div img {
  margin-left: 10px;
  margin-top: 10px;
  width: 70%;
}

.photo-icon-div img {
  margin-left: 10px;
  width: 45px;
}

.icon-div img {
  margin-top: 8px;
  margin-left: 10px;
}

.footer-text {
  width: 343px;
  margin: 0px auto;
  background: #f3eee8;
}

.footer-text p {
  font-size: 13px;
  padding: 20px 10px;
  text-align: center;
}

.footer-text a {
  cursor: pointer;
}

.termsheader h4 {
  margin-left: 78px !important;
}

.termsbody {
  padding: 10px 20px !important;
  height: 400px;
  overflow-y: scroll;
}

.termsbody p {
  font-size: 14px;
}

.termsfooter {
  border: 0px;
  padding: 10px 20px !important;
  display: block !important;
  border-top: 0px !important;
}

.termsfooter a {
  text-decoration: none;
  padding-bottom: 15px;
  display: block;
}

.termsfooter button {
  width: 100%;
  border-radius: 18px;
  background: #000;
  border: 1px solid #000;
  padding: 10px;
}

.termsfooter button:hover {
  width: 100%;
  border-radius: 18px;
  background: #000;
  border: 1px solid #000;
  padding: 10px;
}

.termsfooter button:focus {
  width: 100%;
  border-radius: 18px;
  background: #000;
  border: 1px solid #000;
  padding: 10px;
}

.charLeft {
  padding: 10px;
  display: block;
  text-align: right;
  margin: 0px;
  font-size: 14px;
}

.form-check-label {
  text-align: left;
  font-size: 14px;
  display: inline;
}

.shareerror {
  text-align: center;
  margin: 5px;
  font-size: 14px;
  color: red;
}

.continue-button {
  width: 343px;
  border-radius: 18px !important;
  margin-top: 20px !important;
  margin-bottom: 30px !important;
  margin: auto;
  display: block !important;
}

.sharegift-button-desktop {
  width: 100%;
  border-radius: 18px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block !important;
}

.sharegift-button-mobile {
  width: 100%;
  border-radius: 18px !important;
  margin-top: 20px;
  margin-bottom: 20px;
  display: none !important;
}

.copylink-button {
  width: 100%;
  border-radius: 18px !important;
  margin-bottom: 20px;
}

.sharer-button {
  width: 100%;
  border-radius: 20px;
  color: #fff;
  background: #000;
  padding: 6px;
  margin: 10px 0px;
  display: none;
}

.bottomtext {
  font-size: 14px;
}

.changelink {
  border: 1px solid #fff;
  padding: 5px 8px;
  border-radius: 18px;
  color: #fff;
  text-decoration: none;
  font-weight: 100;
  font-size: 14px;
  right: 9px;
  position: absolute;
  top: 10px;
  background: #4e4e4e;
  cursor: pointer;
}

.title-div p {
  color: #f2efe8;
  text-align: center;
  margin-bottom: 50px;
  font-size: 14px;
}

.error-msg h1 {
  font-size: 25px;
  font-weight: 100;
  color: #f2f2dc;
  text-align: center;
}

.error-msg p {
  font-size: 14px;
  font-weight: 100;
  color: #f2f2dc;
  text-align: center;
}

.addtextcontent {
  text-align: left;
  padding: 10px;
  font-size: 14px;
  word-break: break-word;
}

.occasion-header {
  color: var(--Solid-Black, #000);
  text-align: center;
}

/* Modal css */
.modal-content {
  border-radius: 37px !important;
  width: 80% !important;
  margin-left: 42px !important;
}

.modal-header {
  border: 0px;
  padding-top: 25px !important;
  border-bottom: 0px !important;
}

.textheader h4 {
  margin: 0px auto;
  margin-left: 140px;
  font-weight: initial;
  font-size: 22px;
}

.textbody {
  padding: 20px 50px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.textbody .form-group p {
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  color: #495057;
  float: right;
  font-size: 14px;
}

.textbody textarea {
  background: #f8f9fa;
  border-radius: 18px;
}

.textfooter {
  border: 0px;
  padding: 10px 44px !important;
  display: block !important;
  text-align: center;
  border-top: 0px !important;
}

.textfooter a {
  text-decoration: none;
  padding-bottom: 15px;
  display: block;
}

.textfooter button {
  width: 100%;
  border-radius: 18px;
  background: #000;
  border: 1px solid #000;
  padding: 10px;
}

.textfooter button:hover {
  width: 100%;
  border-radius: 18px;
  background: #000;
  border: 1px solid #000;
  padding: 10px;
}

.btn-close {
  font-size: 12px;
  margin-right: 10px;
}

.input--file {
  position: relative;
  color: #7f7f7f;
}

.input--file input[type='file'] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 145px;
  height: 60px;
}

.input--file span {
  font-size: 34px;
}

.preview {
  width: 100%;
  height: 100%;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  object-fit: cover;
}

.dot-flashing {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  display: inline-block;
  margin-left: 20px;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #fff;
  }

  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.change_div {
  display: inline;
}

.customVideo {
  width: 100% !important;
  height: 100% !important;
}

.continue_div {
  display: inline;
}

.texterrormsg {
  font-size: 12px !important;
  text-align: center;
  color: red !important;
  float: none !important;
}

.clearfix {
  clear: both;
}

.mobile-lefticon {
  float: left;
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 767px) {

  .dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
    display: inline-block;
    margin-left: 20px;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #fff;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: #fff;
    }

    50%,
    100% {
      background-color: rgba(152, 128, 255, 0.2);
    }
  }

  .occasion-title {
    font-weight: 500;
  }

  .occasion-para {
    font-size: 16px;
    line-height: 22px;
  }

  .mobile-lefticon {
    float: left;
    display: block;
  }

  .mobile-lefticon img {
    width: 31px;
    height: 31px;
  }

  .media_selection_title {
    padding: 13px;
    font-size: 21px;
  }

  .share-icon {
    position: fixed;
    bottom: 25px;
    right: 25px;
    cursor: pointer;

    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 5px;
    background-color: rgb(43, 135, 255);
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  }

  .video-section {
    padding: 0px;
  }

  .modal-content {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .change_div {
    width: 50%;
    float: inline-end;
  }

  .continue_div {
    width: 50%;
    padding-bottom: 0px;
    display: block;
  }

  .continue-button {
    width: 100%;
    border-radius: 18px;
    margin-top: 20px !important;
    margin-bottom: 30px !important;
    margin: auto;
    display: block;
  }

  .modal-title h4 {
    margin: 0px auto;
    font-weight: initial;
    font-size: 22px;
    margin-left: 130px;
  }

  .copylink-button {
    display: none !important;
  }

  .bottomtext {
    display: none;
  }

  .video-container {
    margin: 70px auto;
    width: 250px;
    height: 230px;
  }

  .photo-container {
    width: 250px;
    height: 230px;
    margin: auto;
    margin-bottom: 70px;
  }

  .add-photo-container {
    border: 1px solid #4e4e4e;
    width: 100%;
    margin: 15px auto;
    margin-bottom: 0px;
    background: #4e4e4e;
    opacity: 0.8;
    cursor: pointer;
    height: 350px;
    position: relative;
  }

  .add-video-container {
    border: 1px solid #4e4e4e;
    width: 100%;
    margin: 15px auto;
    margin-bottom: 0px;
    background: #4e4e4e;
    opacity: 0.8;
    cursor: pointer;
    height: 450px;
    position: relative;
  }

  .add-text-container {
    border: 1px solid #fff;
    width: 100%;
    margin: 15px auto;
    margin-top: 0px;
    background: #fff;
    opacity: 0.94;
    cursor: pointer;
  }

  .photo-icon-div {
    background-color: beige;
    border: 1px solid beige;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    margin: 0px auto;
    position: relative;
  }

  .footer-text {
    width: 100%;
    background: #fff;
    margin-top: 20px;
  }

  .sharegift-button-desktop {
    width: 100%;
    border-radius: 18px !important;
    margin-top: 20px;
    margin-bottom: 20px;
    display: none !important;
  }

  .sharegift-button-mobile {
    width: 100%;
    border-radius: 18px !important;
    margin-top: 20px;
    margin-bottom: 20px;
    display: block !important;
  }

  .sharer-button {
    width: 100%;
    border-radius: 20px;
    color: #fff;
    background: #000;
    padding: 6px;
    margin: 10px 0px;
    display: block;
  }
}

.occasion-header {
  color: var(--Solid-Black, #000);
  text-align: center;
}

.occasion-list {
  color: var(--Solid-Black, #000);
  text-align: center;
  /* Button Big - 18px - 150% */

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.occasion-image {
  border-radius: 25px;
  /* Card Shadow */
  box-shadow: 1px 0px 8px 0px rgba(113, 113, 113, 0.1);
}

.button-p {
  display: flex;
  height: 35px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--Solid-Black, #000);
}

.height-100 {
  height: 100px;
}

@media only screen and (max-width: 1026px) {
  .gift-image-div {
    display: none;
  }

  .occasion-header-font-size {
    font-size: 24px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 1026px) {
  .body-padding {
    padding-left: 48px;
    padding-right: 48px;
  }

  .body-margin {
    margin-left: 48px;
    margin-right: 48px;
  }

  .occasion-header-font-size {
    font-size: 40px;
    font-weight: 500;
  }
}

/*Birthday Icon*/
.birthday-fb-icon {
  background-image: url('./icons/icon-birthday.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.birthday-fb-icon:hover {
  background-image: url('./icons/icon-birthday-hover.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.birthday-fb-icon:focus {
  background-image: url('./icons/icon-birthday-active.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

/*Graduation Icon*/
.graduation-fb-icon {
  background-image: url('./icons/icon-graduation.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.graduation-fb-icon:hover {
  background-image: url('./icons/icon-graduation-hover.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.graduation-fb-icon:focus {
  background-image: url('./icons/icon-graduation-active.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

/* Anniversary Icon
.annivesary-fb-icon {
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.annivesary-fb-icon path {
  stroke: black;
}

.annivesary-fb-icon rect {
  fill: black;
  stroke: black;
}

.annivesary-fb-icon:hover path {
  stroke: #CF0989;
}

.annivesary-fb-icon:hover rect {
  fill: #F2EFE8;
  stroke: #CF0989;
}

.annivesary-fb-icon:focus path {
  stroke: white;
} */

.occasion-fb-icon svg:hover path {
  stroke: #cf0989;
}

.occasion-fb-icon svg:hover #rect1 {
  fill: #f2efe8;
}

.occasion-fb-icon svg:hover #rect2 {
  stroke: #cf0989;
}

.occasion-fb-icon:focus svg path {
  stroke: white;
}

.occasion-fb-icon:focus svg #rect1 {
  fill: #cf0989;
}

.occasion-fb-icon:focus svg #rect2 {
  stroke: #cf0989;
}

/* .annivesary-fb-icon:hover {
  svg {
    rect {
      fill: #F2EFE8;
      stroke: #CF0989;
    } 
  }
  
} */

.annivesary-fb-icon:focus rect {
  stroke: #cf0989;
  fill: '#CF0989';
}

/*Thanks Icon*/
.thanks-fb-icon {
  background-image: url('./icons/icon-thanks.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.thanks-fb-icon:hover {
  background-image: url('./icons/icon-thanks-hover.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.thanks-fb-icon:focus {
  background-image: url('./icons/icon-thanks-active.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

/*Baby Icon*/
.baby-fb-icon {
  background-image: url('./icons/icon-baby.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.baby-fb-icon:hover {
  background-image: url('./icons/icon-baby-hover.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.baby-fb-icon:focus {
  background-image: url('./icons/icon-baby-active.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

/*Other Icon*/
.other-fb-icon {
  background-image: url('./icons/icon-other.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.other-fb-icon:hover {
  background-image: url('./icons/icon-other-hover.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.other-fb-icon:focus {
  background-image: url('./icons/icon-other-active.svg');
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
  display: inline-block;
}

.container-custom {
  margin-top: 100px;
}

.r-margin-x {
  margin-left: 50px;
  margin-right: 50px;
}

.r-margin-right-10 {
  margin-right: 25px;
  width: 60%;
  padding-left: 100px;
}

.r-margin-right-10 {
  margin-right: 25px;
  width: 60%;
}

.mask-layer {
  height: 480px;
  width: 100%;
  background: var(--gradient-angled-gradient-theme-angled, linear-gradient(135deg, #3e53a4 0%, #cf0989 100%));
  position: relative;
}

/* .mask-layer-complete {
  height: 480px;
  width: 100%;
  background: white;
  position: relative;
} */

.scratch-div {
  width: 244.734px;
  height: 107.063px;
  flex-shrink: 0;
  top: 20%;
  left: 53%;
  position: absolute;
  transform: translate(-50%, -50%);
  color: var(--Off-White, #f2efe8);
  text-align: center;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .scratch-div-icon {
    background-image: url('../assets/img/tap-scratch.png');
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .scratch-div-icon {
    background-image: url('../assets/img/click-scratch.png');
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}

.mobile-div {
  display: none;
}

.desktop-div {
  display: block;
}

@media screen and (max-width: 768px) {
  .mobile-div {
    display: block;
  }

  .desktop-div {
    display: none;
  }
}

.gift-div {
  width: 112.474px;
  height: 93.055px;
  flex-shrink: 0;
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gift-icon-div {
  background-image: url('../assets/img/gift-icon.png');
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.tap-div {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0px 30px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  flex-shrink: 0;
  position: absolute;
  top: 60%;
}

.tap-div-icon {
  background-repeat: no-repeat;
  display: flex;
  width: 55px;
  height: 55px;
  padding: 1.406px 7.606px 1.406px 9.844px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.tap-layer-animation {
  animation: tap-animation 1s infinite;
}

@keyframes tap-animation {
  0% {
    background-image: url('../assets/img/tap1.png');
  }

  33% {
    background-image: url('../assets/img/tap2.png');
  }

  66% {
    background-image: url('../assets/img/tap3.png');
  }

  100% {
    background-image: url('../assets/img/tap4.png');
  }
}

.tap-div-content {
  display: flex;
  height: 75px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  color: var(--Off-White, #f2efe8);
  text-align: center;
}

.mask-sub-layer {
  background-position: center;
  height: 100%;
  width: 100%;
  /* background-image: url(''); */
  background-size: cover;
  background-repeat: no-repeat;
  mask-image: url('../assets/img/empty.jpg');
  mask-repeat: no-repeat;
  mask-position: 50% 10%;
  z-index: 2;
  border-radius: 24px;
}

.mask-sub-layer-animation-inTransit {
  animation: mask-animation-inTransit 2s 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.mask-sub-layer-animation-otd {
  animation: mask-animation-otd 2s 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.mask-sub-layer-animation-delivered {
  animation: mask-animation-delivered 4s 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.mask-sub-layer-animation-pre-inTransit {
  animation: mask-animation-inTransit 0.1s 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.mask-sub-layer-animation-pre-otd {
  animation: mask-animation-otd 0.1s 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.mask-sub-layer-animation-pre-delivered {
  animation: mask-animation-delivered 0.1s 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.mask-sub-layer-animation-pre-scratch-otd {
  animation: mask-animation-pre-otd 1s 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.mask-sub-layer-animation-pre-scratch-delivered-inTransit {
  animation: mask-animation-pre-scratch-delivered-inTransit 3s 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.mask-sub-layer-animation-pre-scratch-delivered-otd {
  animation: mask-animation-pre-scratch-delivered-otd 3s 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

:root {
  --mask-0: url('../assets/img/1.png');
  --mask-1: url('../assets/img/2.png');
  --mask-2: url('../assets/img/3.png');
  --mask-3: url('../assets/img/4.png');
  --mask-4: url('../assets/img/5.png');
  --mask-5: url('../assets/img/6.png');
  --mask-6: url('../assets/img/7.png');
  --mask-7: url('../assets/img/8.png');
  --mask-8: url('../assets/img/9.png');
  --mask-9: url('../assets/img/10.png');
  --mask-10: url('../assets/img/11.png');
  --mask-11: url('../assets/img/12.png');
  --mask-12: url('../assets/img/13B.png');
  --mask-13: url('../assets/img/14B.png');
  --mask-14: url('../assets/img/15B.png');
  --mask-15: url('../assets/img/16B.png');
  --mask-16: url('../assets/img/17B.png');
  --mask-17: url('../assets/img/18B.png');
  --mask-18: url('../assets/img/19B.png');
  --mask-19: url('../assets/img/20B.png');
  --mask-20: url('../assets/img/21B.png');
  --mask-21: url('../assets/img/22B.png');
  --mask-22: url('../assets/img/23B.png');
  --mask-23: url('../assets/img/24B.png');
  --mask-24: url('../assets/img/25B.png');
  --mask-25: url('../assets/img/26B.png');
}

@keyframes mask-animation-inTransit {
  0% {
    mask-image: var(--mask-0);
  }

  20% {
    mask-image: var(--mask-1);
  }

  40% {
    mask-image: var(--mask-2);
  }

  60% {
    mask-image: var(--mask-3);
  }

  80% {
    mask-image: var(--mask-4);
  }

  100% {
    mask-image: var(--mask-5);
  }
}

@keyframes mask-animation-pre-otd {
  0% {
    mask-image: ur var(--mask-6);
  }

  20% {
    mask-image: var(--mask-7);
  }

  40% {
    mask-image: var(--mask-8);
  }

  60% {
    mask-image: var(--mask-9);
  }

  80% {
    mask-image: var(--mask-10);
  }

  100% {
    mask-image: var(--mask-11);
  }
}

@keyframes mask-animation-otd {
  0% {
    mask-image: var(--mask-0);
  }

  10% {
    mask-image: var(--mask-1);
  }

  20% {
    mask-image: var(--mask-3);
  }

  30% {
    mask-image: var(--mask-4);
  }

  40% {
    mask-image: var(--mask-5);
  }

  50% {
    mask-image: var(--mask-6);
  }

  60% {
    mask-image: var(--mask-7);
  }

  70% {
    mask-image: var(--mask-8);
  }

  80% {
    mask-image: var(--mask-9);
  }

  90% {
    mask-image: var(--mask-10);
  }

  100% {
    mask-image: var(--mask-11);
  }
}

@keyframes mask-animation-delivered {
  0% {
    mask-image: var(--mask-0);
  }

  5% {
    mask-image: var(--mask-1);
  }

  10% {
    mask-image: var(--mask-2);
  }

  15% {
    mask-image: var(--mask-3);
  }

  20% {
    mask-image: var(--mask-4);
  }

  25% {
    mask-image: var(--mask-5);
  }

  30% {
    mask-image: var(--mask-6);
  }

  35% {
    mask-image: var(--mask-7);
  }

  40% {
    mask-image: var(--mask-8);
  }

  45% {
    mask-image: var(--mask-9);
  }

  50% {
    mask-image: var(--mask-10);
  }

  55% {
    mask-image: var(--mask-11);
  }

  60% {
    mask-image: var(--mask-13);
  }

  65% {
    mask-image: var(--mask-15);
  }

  70% {
    mask-image: var(--mask-17);
  }

  75% {
    mask-image: var(--mask-19);
  }

  80% {
    mask-image: var(--mask-21);
  }

  85% {
    mask-image: var(--mask-22);
  }

  90% {
    mask-image: var(--mask-23);
  }

  95% {
    mask-image: var(--mask-24);
  }

  100% {
    mask-image: var(--mask-25);
  }
}

@keyframes mask-animation-pre-scratch-delivered-inTransit {
  0% {
    mask-image: var(--mask-5);
  }

  5% {
    mask-image: var(--mask-6);
  }

  10% {
    mask-image: var(--mask-7);
  }

  15% {
    mask-image: var(--mask-8);
  }

  20% {
    mask-image: var(--mask-9);
  }

  25% {
    mask-image: var(--mask-10);
  }

  30% {
    mask-image: var(--mask-11);
  }

  35% {
    mask-image: var(--mask-12);
  }

  40% {
    mask-image: var(--mask-13);
  }

  45% {
    mask-image: var(--mask-14);
  }

  50% {
    mask-image: var(--mask-15);
  }

  55% {
    mask-image: var(--mask-16);
  }

  60% {
    mask-image: var(--mask-17);
  }

  65% {
    mask-image: var(--mask-18);
  }

  70% {
    mask-image: var(--mask-19);
  }

  75% {
    mask-image: var(--mask-20);
  }

  80% {
    mask-image: var(--mask-21);
  }

  85% {
    mask-image: var(--mask-22);
  }

  90% {
    mask-image: var(--mask-23);
  }

  95% {
    mask-image: var(--mask-24);
  }

  100% {
    mask-image: var(--mask-25);
  }
}

@keyframes mask-animation-pre-scratch-delivered-otd {
  6% {
    mask-image: var(--mask-11);
  }

  12% {
    mask-image: var(--mask-12);
  }

  18% {
    mask-image: var(--mask-13);
  }

  24% {
    mask-image: var(--mask-14);
  }

  30% {
    mask-image: var(--mask-15);
  }

  36% {
    mask-image: var(--mask-16);
  }

  42% {
    mask-image: var(--mask-17);
  }

  48% {
    mask-image: var(--mask-18);
  }

  54% {
    mask-image: var(--mask-19);
  }

  60% {
    mask-image: var(--mask-20);
  }

  66% {
    mask-image: var(--mask-21);
  }

  72% {
    mask-image: var(--mask-22);
  }

  78% {
    mask-image: var(--mask-23);
  }

  84% {
    mask-image: var(--mask-24);
  }

  100% {
    mask-image: var(--mask-25);
  }
}

.r-scratch {
  flex-shrink: 0;
  border-radius: 24px;
}

.r-header {
  text-align: center;
  padding-top: 10px 0px;
}

.r-header-color {
  color: #cf0989;
}

.r-subheader {
  text-align: center;
  padding-bottom: 10px;
  color: var(--Dark-Gray, #4e4e4e);
}

.r-background {
  background: url('../../../public/assets/img/Birthday-BG.png');
  padding-top: 17px;
  height: 100%;
}

.r-background-birthday {
  background: url('../../../public/assets/img/Birthday-BG.png');
}

.r-background-anniversary {
  background: url('../../../public/assets/img/Anniversary-BG.png');
}

.r-background-graduation {
  background: url('../../../public/assets/img/Graduation-BG.png');
}

.r-background-thanks {
  background: url('../../../public/assets/img/Thanks-BG.png');
}

.r-background-baby {
  background: url('../../../public/assets/img/Baby-BG.png');
}

.r-background-other {
  background: url('../../../public/assets/img/Other-BG.png');
}

.r-delivery {
  align-items: center;
  border-radius: 25px;
  border-right: 1px solid var(--Light-Gray, #c0c0c0);
  border-bottom: 1px solid var(--Light-Gray, #c0c0c0);
  border-left: 1px solid var(--Light-Gray, #c0c0c0);
  background: #fff;
  width: 100%;
}

.r-delivery-title {
  border-radius: 20px 20px 0px 0px;
  background: var(--Dark-Gray, #4e4e4e);
  width: 100%;
  padding: 15px 20px;
  color: var(--white, var(--White, #fff));
  text-align: center;
}

.r-delivery-title-content {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  /* 20.8px */
  letter-spacing: 0.32px;
}

.r-delivery-title-content-sub {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.r-pack-info-1 {
  color: var(--Solid-Black, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  /* 128.571% */
}

.r-pack-info-2 {
  color: var(--Dark-Gray, #4e4e4e);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.r-work {
  display: flex;
  padding: 5px 14px 10px 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--white, #fff);
  margin-top: 20px;
  width: 100%;
}

.r-work-header {
  display: flex;
  height: 30px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: var(--Dark-Gray, #4e4e4e);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.r-work-step {
  align-self: stretch;
}

.r-work-step-phone {
  background: url('../assets/img/phone-img.png');
  background-repeat: no-repeat;
  width: 100%;
  height: 120px;
}

.r-work-step-msg {
  background: url('../assets/img/msg-img.png');
  background-repeat: no-repeat;
  width: 100%;
  height: 120px;
}

.r-work-step-gift {
  background: url('../assets/img/gift-img.png');
  background-repeat: no-repeat;
  width: 100%;
  height: 120px;
}

.r-work-step-header {
  color: var(--Magenta, #cf0989);
  font-feature-settings: 'clig' off, 'liga' off;
  /* Subtitle Bold - 14px */
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  /* 114.286% */
  letter-spacing: 0.28px;
}

.r-work-step-content {
  align-self: stretch;
  color: var(--Solid-Black, #000);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.1px;
}

.r-work-step-content-note {
  align-self: stretch;
  color: var(--Dark-Gray, #4e4e4e);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.r-stay-in-loop-btn {
  width: 100%;
  display: flex;
  height: 35px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--Solid-Black, #000);
  color: white;
  color: var(--white, var(--White, #fff));
  /* Text Normal - 14px - 150% */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
  letter-spacing: 0.28px;
}

.r-phone-text-field {
  border-radius: 24px;
  border: 1px solid var(--gray-10, #eeeff0);
  background: var(--gray-5, #f7f7f8);
  display: flex;
  height: 34px;
  padding: 8px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  width: 100%;
}

.r-phone-text-field-err {
  border: 1px solid var(--red, #f75555);
  background: var(--gray-5, #f7f7f8);
  border-radius: 24px;
}

.r-phone-text-field-content {
  margin-top: 5px;
  padding-left: 5px;
  color: var(--Dark-Gray, #4e4e4e);
  align-self: stretch;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.r-t-n-c {
  color: var(--Dark-Gray, #4e4e4e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 18px */
}

.r-t-n-dialog-content {
  color: var(--Dark-Gray, #4e4e4e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 18px */
}

.r-hide-details-link {
  color: var(--Data-Viz-02, #0384d4) !important;
  text-align: center;
  border-radius: 25px;
}

.r-hide-details-link a {
  text-decoration: none;
}

.r-text-right {
  text-align: right;
}

.r-dont-miss-thing-dialog {
  display: flex;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--White, #fff);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07);
}

.r-dont-miss-thing-dialog-content {
  border: 0px;
}

.r-dialog-header {
  color: var(--Solid-Black, #000);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
}

.r-dialog-body {
  color: var(--Dark-Gray, #4e4e4e);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
  letter-spacing: 0.28px;
}

.r-dialog-loop-content {
  color: var(--Dark-Gray, #4e4e4e);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 21px */
  letter-spacing: 0.28px;
}

.r-dialog-loop-btn {
  display: flex;
  height: 34px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 24px;
  background: var(--Solid-Black, #000);
}

.r-dialog-close-btn {
  display: flex;
  height: 35px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 16px;
  background: white;
}

.w-40 {
  width: 35% !important;
}

.w-60 {
  width: 65% !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.sun-modal-header {
  padding-top: 20px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 15px !important;
}

.sub-modal-body {
  padding-top: 0px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 0px !important;
}

.sub-modal-footer {
  padding-top: 0px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 20px !important;
}

.sub-modal-loop-btn {

  height: 34px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

}

.sub-modal-loop-btn {

  height: 34px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 24px;
  background: var(--Solid-Black, #000);
  color: white;
}

.sub-modal-close-btn {
  display: flex;
  height: 35px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 0px;
  color: #0384d4;
  background-color: white;
}

.r-dialog-header {
  color: var(--Solid-Black, #000);
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  /* 33px */
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .r-tracking-info {
    order: 2;
  }

  .r-gift-info {
    order: 1;
  }


}

.r-photo-text {
  display: flex;
  width: 343px;
  height: 110px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: white;
  width: 100%;
}

.r-gift {
  cursor: pointer;
}

.r-flex-container {
  display: flex;
  justify-content: center;
}

.r-gift-banner {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--White, #fff);
}

.r-gift-banner-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: var(--Dark-Gray, #4e4e4e);
  font-size: 21px;
  line-height: 112%;
  /* 23.52px */
  letter-spacing: -0.21px;
}

.r-gift-banner-body {
  color: var(--Dark-Gray, #4e4e4e);
  font-size: 14px;
  line-height: 150%;
  /* 21px */
  letter-spacing: 0.28px;
}

table.rounded-corners {
  border-radius: 25px;
}

.r-remove-save-img {
  pointer-events: none;
}

.sub-modal-loop-btn {
  display: block;
  padding: 0px 20px;
}

.r-error-color {
  color: '#F75555';
}

.r-animation-box {
  height: '100%';
  width: '374px';
}

.r-w-35 {
  width: '35%';
}

.r-w-65 {
  width: '65%';
}