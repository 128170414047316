.errormsg {
  color: red;
  font-size: 14px;
  display: none;
}

.Showerrormsg {
  color: red;
  font-size: 14px;
  display: block;
}

.Hideerrormsg {
  color: red;
  font-size: 14px;
  display: none;
}

.validatemethod {
  border: 1px solid red !important;
}

.defaultmethod {
  border: 1px solid #a6a6a6 !important;
}
